import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getRiskNumericClass, getRiskScoreClass } from 'utils/utils';
import ScoreBox from './score-box';
import EditableScore from './editable-score';
import { useState } from 'react';

const ScoreSection = ({ title, scores, votePreview, isPostMitigation, onScoreChange }) => {
  const [editingCell, setEditingCell] = useState(null);
  const [errorValue, setErrorValue] = useState(null);
  const labels = ['Probability', 'Severity', 'Detectability', 'Score'];
  const scoreData = title === 'Before Mitigation' ? scores?.before : scores?.afterMitigation;
  const isBefore = title === 'Before Mitigation';

  const handleScoreChange = (key, value, rowLabel) => {
    const section = isBefore ? 'before' : 'after';
    onScoreChange(section, key, value, rowLabel);
  };

  const renderScores = (rowLabel) => {
    const scoreTypes = ['Probability', 'Severity', 'Detectability', 'RiskScore'];
    return scoreTypes.map((key) => {
      const value = scoreData?.[rowLabel]?.[key] || 0;
      const isEditing =
        editingCell === `${rowLabel}-${key}` && rowLabel === 'humans' && key !== 'RiskScore';

      return (
        <Box
          key={key}
          onClick={() =>
            rowLabel === 'humans' && key !== 'RiskScore' && setEditingCell(`${rowLabel}-${key}`)
          }
          sx={{
            cursor:
              !votePreview && rowLabel === 'humans' && key !== 'RiskScore' ? 'pointer' : 'default',
            height: '100%',
            width: '100%',
            paddingBottom: editingCell === `${rowLabel}-${key}` ? '1rem' : '0.25rem' // Now using errorValue state
          }}
        >
          {isEditing && !votePreview ? (
            <EditableScore
              value={value}
              onChange={(e) => {
                const newValue = e.target.value === '' ? 0 : parseInt(e.target.value);
                if (!isNaN(newValue)) {
                  const isValid = newValue >= 1 && newValue <= 6;

                  if (isValid) {
                    setErrorValue(null);
                    handleScoreChange(key, newValue, rowLabel);
                  } else {
                    setErrorValue(newValue);
                  }
                }
              }}
              onFinish={() => {
                setEditingCell(null);
                setErrorValue(null);
              }}
              min={key === 'RiskScore' ? 1 : 1}
              max={key === 'RiskScore' ? 150 : 6}
              error={errorValue !== null}
            />
          ) : (
            <ScoreBox
              value={value}
              className={
                key === 'RiskScore' ? getRiskScoreClass(value) : getRiskNumericClass(value)
              }
              isActive={votePreview ? (isBefore ? !isPostMitigation : isPostMitigation) : true}
            />
          )}
        </Box>
      );
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{
          mb: 1,
          color: votePreview
            ? isPostMitigation === !isBefore
              ? 'primary.main'
              : 'grey.300'
            : 'text.primary'
        }}
      >
        {title}
        {votePreview && isPostMitigation === !isBefore && (
          <ArrowUpwardIcon sx={{ fontSize: 'inherit' }} />
        )}
      </Typography>

      <Box
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 1,
          overflow: 'hidden',
          bgcolor: 'background.paper'
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '6rem 1fr' }}>
          {/* Header */}
          <Typography
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 500,
              whiteSpace: 'pre-line',
              textAlign: 'center',
              borderRight: '1px solid rgba(0,0,0,0.12)',
              bgcolor: 'grey.50',
              fontSize: '0.875rem'
            }}
          >
            Risk
          </Typography>

          <Box
            sx={{
              bgcolor: 'grey.50'
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '0.25rem',
                p: 0.75
              }}
            >
              {labels.map((text) => (
                <Typography
                  key={text}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    fontWeight: 500,
                    whiteSpace: 'pre-line',
                    textAlign: 'center',
                    fontSize: '0.875rem'
                  }}
                >
                  {text}
                </Typography>
              ))}
            </Box>
          </Box>

          {/* Score Rows */}
          {['ai', 'humans'].map((rowLabel) => (
            <React.Fragment key={rowLabel}>
              <Box
                sx={{
                  borderRight: '1px solid rgba(0,0,0,0.12)',
                  borderTop: '1px solid rgba(0,0,0,0.12)',
                  display: 'flex',
                  alignItems: 'center',
                  px: 1,
                  bgcolor: 'grey.50'
                }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                  {rowLabel === 'ai' ? 'AI' : 'Humans'}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderTop: '1px solid rgba(0,0,0,0.12)',
                  p: 0.75,
                  bgcolor: 'background.paper'
                }}
              >
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0.3rem' }}>
                  {renderScores(rowLabel)}
                </Box>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const RiskAssessmentScore = ({ formData, isPostMitigation, votePreview, onScoreChange }) => {
  const finalScore =
    formData?.scores?.afterMitigation?.ai?.RiskScore ||
    formData?.scores?.before?.ai?.RiskScore ||
    0;

  const handleScoreChange = (section, key, value) => {
    if (key !== 'RiskScore') {
      const scores =
        section === 'before'
          ? formData.scores.before.humans
          : formData.scores.afterMitigation.humans;

      const updatedScores = { ...scores, [key]: value };
      const riskScore =
        updatedScores.Probability * updatedScores.Severity * updatedScores.Detectability;

      onScoreChange(section, key, value, 'humans');
      onScoreChange(section, 'RiskScore', riskScore, 'humans');
    } else {
      onScoreChange(section, key, value, 'humans');
    }
  };

  return (
    <Grid item xs={12}>
      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mb={1}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ color: 'primary.main' }}>
          Final Score:
        </Typography>
        <Box
          className={getRiskScoreClass(finalScore)}
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            padding: '0.15rem 0.5rem',
            marginLeft: '0.5rem',
            minWidth: '2rem',
            textAlign: 'center',
            borderRadius: 1
          }}
        >
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {Number(finalScore)}
          </Typography>
        </Box>
      </Box>

      <ScoreSection
        title="Before Mitigation"
        scores={formData?.scores}
        votePreview={votePreview}
        isPostMitigation={isPostMitigation}
        onScoreChange={handleScoreChange}
      />

      <ScoreSection
        title="After Mitigation"
        scores={formData?.scores}
        votePreview={votePreview}
        isPostMitigation={isPostMitigation}
        onScoreChange={handleScoreChange}
      />
    </Grid>
  );
};

export default RiskAssessmentScore;
