import { httpService } from './http.service.js';

const ENDPOINT = 'mqtt-sessions/';

export const mqttSessionService = {
  getRiskSession,
  getCurrentRiskSession,
  createRiskSession,
  updateRiskSessions,
  updateRiskSession,
  endRiskSession,
  updateRiskReviewers
};

async function getRiskSession(sessionId) {
  return await httpService.get(`${ENDPOINT}/risk/${sessionId}`);
}

async function getCurrentRiskSession(sessionId) {
  return await httpService.get(`${ENDPOINT}/risk/current/${sessionId}`);
}

async function createRiskSession(data) {
  return await httpService.post(`${ENDPOINT}/risk`, data);
}

async function updateRiskSessions(sessionId, data) {
  return await httpService.put(`${ENDPOINT}/risk/session/${sessionId}`, data);
}

async function updateRiskSession(id, data) {
  return await httpService.put(`${ENDPOINT}/risk/${id}`, data);
}

async function updateRiskReviewers(id, review, isPostMitigation) {
  console.log('updateRiskReviewers', id, review, isPostMitigation);
  
  return await httpService.put(
    `${ENDPOINT}/risk/review/${id}?postMitigation=${isPostMitigation}`,
    review
  );
}

async function endRiskSession(sessionId) {
  return await httpService.delete(`${ENDPOINT}/risk/session/${sessionId}`);
}
