import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';

const RiskReviewUsersTable = ({ data = [] }) => {
  return (
    <TableContainer sx={{ height: 'calc(80lvh - 335px)' }} component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="User voting results table">
        <TableHead>
          <TableRow>
            <TableCell size="small">#</TableCell>
            <TableCell size="small" sx={{ pr: 0 }}>
              Name
            </TableCell>
            <TableCell size="small" align="right">
              Probability
            </TableCell>
            <TableCell size="small" align="right">
              Severity
            </TableCell>
            <TableCell size="small" align="right">
              Detectability
            </TableCell>
            {/* <TableCell align="right">Time</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody sx={{ fontSize: '2rem', fontWeight: 'typography.fontWeightMedium' }}>
          {!data ||
            (data.length < 1 && (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={6} component="th" scope="row">
                  <Typography
                    color="grey.600"
                    variant="subtitle2"
                    sx={{ width: '100%', textAlign: 'center' }}
                  >
                    No review records
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          {data.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{
                borderBottom: '1px solid rgba(0,0,0,0.12)',
                '&:last-child td, &:last-child th': { border: 0 },
                '& td, & th': { fontSize: '1.25rem' }
              }}
            >
              <TableCell variant="head" component="th">
                {index + 1}.
              </TableCell>
              <TableCell scope="row" sx={{ pr: 0 }}>
                {row.displayName}
              </TableCell>
              <TableCell variant="head" align="right">
                {row.probability}
              </TableCell>
              <TableCell variant="head" align="right">
                {row.severity}
              </TableCell>
              <TableCell variant="head" align="right">
                {row.detectability}
              </TableCell>
              {/* <TableCell align="right">{new Date(row.timestamp).toLocaleTimeString()}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RiskReviewUsersTable;
