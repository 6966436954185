import { Grid, MenuItem, TextField } from '@mui/material';

const RiskAssessmentForm = ({ formData, handleChangeData }) => {
  return (
    <>
      {/* Description Section */}
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={8} lg={5}>
            <TextField
              size="small"
              label="Source"
              fullWidth
              variant="outlined"
              value={formData.source || ''}
              onChange={handleChangeData('source')}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <TextField
              size="small"
              label="Product Version"
              fullWidth
              variant="outlined"
              value={formData.productVersion || ''}
              onChange={handleChangeData('productVersion')}
            />
          </Grid>
          <Grid item xs={8} lg={4}>
            <TextField
              fullWidth
              size="small"
              select
              label="Mitigation Status"
              value={formData.mitigationStatus || 'A'}
              onChange={handleChangeData('mitigationStatus')}
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* Mitigation Section */}
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <TextField
              size="small"
              label="Mitigation Measures"
              fullWidth
              variant="outlined"
              value={formData['Mitigation Measures'] || ''}
              onChange={handleChangeData('Mitigation Measures')}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              label="Mitigation by AI"
              fullWidth
              variant="outlined"
              value={formData.mitigationByAI || ''}
              onChange={handleChangeData('mitigationByAI')}
            />
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              label="Mitigation by Human"
              fullWidth
              variant="outlined"
              value={formData.mitigationByHuman || ''}
              onChange={handleChangeData('mitigationByHuman')}
            />
          </Grid> */}
        </Grid>
      </Grid>

      {/* Status Section */}
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6} lg={3}>
            <TextField
              size="small"
              label="Validation Test #"
              fullWidth
              variant="outlined"
              value={formData.validationNum || ''}
              onChange={handleChangeData('validationNum')}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <TextField
              size="small"
              label="Validation Status"
              fullWidth
              variant="outlined"
              value={formData.validationStatus || ''}
              onChange={handleChangeData('validationStatus')}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <TextField
              size="small"
              label="Verification Test #"
              fullWidth
              variant="outlined"
              value={formData.verificationNum || ''}
              onChange={handleChangeData('verificationNum')}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <TextField
              size="small"
              label="Verification Test Status"
              fullWidth
              variant="outlined"
              value={formData.verificationStatus || ''}
              onChange={handleChangeData('verificationStatus')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RiskAssessmentForm;
