import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CorporateFareSharpIcon from '@mui/icons-material/CorporateFareSharp';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { ButtonGroup, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useQuery } from '@tanstack/react-query';
import OrgCreateEdit from 'components/org/org-create-edit';
import { fetchOrgList, useOrgData } from 'contexts/org-data-context';
import React, { useEffect, useMemo, useState } from 'react';
import { LiaJira } from 'react-icons/lia';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { aiProductService } from 'services/ai-product.service';
import { DeleteIcon, EditIcon, PlusIcon } from '../../icons';
import { orgService } from '../../services/org.service';

import { useConfirmDialog } from 'contexts/confirm-dialog-context';
import { PRODUCT_TYPES, RISK_PRODUCT_TYPES } from 'lib/constants/compliance';
import './tree-hirarchial-list.css';
import { uniq } from 'lodash';
import { useAuth } from 'contexts/auth-context';

const TreeHierarchicalList = () => {
  const [expanded, setExpanded] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNode, setselectedNode] = useState(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const prId = searchParams.get('prId');

  const { openConfirmDialog } = useConfirmDialog(); // Destructure openDialog from useDialog

  const { user } = useAuth();

  const {
    state: { rootOrg, rootOrgId, selectedOrgId, selectedOrg, orgList },
    dispatch
  } = useOrgData();

  /* eslint-disable no-unused-vars */
  const { data: aiProducts, isPending } = useQuery({
    queryKey: ['aiProducts'],
    queryFn: aiProductService.getAll
  });
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    const getNodeIdsOfParents = (nodes) => {
      if (!nodes) return [];

      let nodeIds = [];
      let currNode = nodes;
      while (currNode?.parent_id !== null) {
        nodeIds.push(currNode._id);
        const pId = currNode.parent_id;
        currNode = orgList.find((org) => org._id === pId);
      }

      if (currNode?._id) {
        nodeIds.push(currNode._id);
      }
      if (prId) {
        nodeIds.push(`${selectedOrg._id}-products`);
      }

      return nodeIds;
    };

    if (selectedOrg && orgList.length && !expanded.length) {
      setExpanded(getNodeIdsOfParents(selectedOrg).concat(`${selectedOrg._id}-products`));
    }
  }, [selectedOrg, expanded.length, orgList, prId]);

  const treeData = useMemo(() => {
    if (!rootOrg) return [];
    return [rootOrg];
  }, [rootOrg]);

  const selectedProduct = useMemo(
    () => (prId ? aiProducts?.find((pr) => pr._id === prId) : null),
    [aiProducts, prId]
  );

  const handleOpen = (org = null, isNew = false) => {
    console.log('Opening dialog:', { org, isNew });

    if (isNew) {
      setselectedNode({
        name: '',
        org_id: rootOrgId,
        parent_id: selectedOrgId
      });
    } else {
      setselectedNode(org);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setselectedNode(null);
  };

  const saveOrg = async (org) => {
    if (selectedNode) {
      org.org_id = selectedNode.org_id;
      org.parent_id = selectedNode.parent_id;
    }
    await orgService.save(org);

    fetchOrgList(dispatch, selectedOrgId);
    handleClose();
  };

  const openDeleteDialog = (org) => {
    console.log('Opening delete confirmation for:', org); // Debugging line
    openConfirmDialog({
      title: 'Confirm Delete',
      body: `Delete organization '${org.name}' with id '${org._id}'? This action is permanent.`,
      onConfirm: async () => {
        console.log('Confirmed delete for:', org); // Debugging line
        await orgService.remove(org._id);
        navigateToId({ _id: org.parent_id });
        fetchOrgList(dispatch, org.parent_id);
      },
      onCancel: () => console.log('Delete canceled') // Optional cancel action with debugging
    });
  };
  const handleNodeClick = (event, node, nodeType) => {
    event.stopPropagation();
    try {
      // do not navigate if expand / collapse was clicked
      const targetEl = event.target.getAttribute('data-testid');
      if (targetEl === 'TreeViewExpandIconIcon' || targetEl === 'TreeViewCollapseIconIcon') {
        return;
      }
    } catch (err) {}
    handleItemExpansionToggle(node._id, true, nodeType === 'product');
    navigateToId(node, nodeType);
  };

  const navigateToId = (node, nodeType = 'org') => {
    console.log('navigateToId', nodeType, node._id);
    const targetId = node._id;
    if (!targetId && nodeType === 'org') return;
    const locationParts = location.pathname.split('/');
    if (nodeType === 'product') {
      if (targetId) {
        const prType = node.type;
        if (
          locationParts.indexOf('product') > -1 ||
          locationParts.indexOf('products') > -1 ||
          (locationParts.indexOf('risk') > -1 && RISK_PRODUCT_TYPES.indexOf(prType) < 0)
        ) {
          return navigate({
            pathname: `/compliance/${node.org_id}/product/${targetId}`,
            search: `?prId=${targetId}&prType=${prType}`
          });
        }
        locationParts[2] = node.org_id;
        return navigate({
          pathname: locationParts.join('/'),
          search: `?prId=${targetId}&prType=${prType}`
        });
      }
      // navigate to new product page
      return navigate(`/compliance/${node.org_id}/product`);
    } else {
      // handle org nav
      let newpath = '';
      if (locationParts.indexOf('product') > -1) {
        newpath = `/compliance/${targetId}/products`;
      } else if (locationParts.indexOf('custom-policy') > -1) {
        newpath = `/compliance/${targetId}/custom-policies`;
      } else if (locationParts.indexOf('risk') > -1) {
        newpath = `/compliance/${targetId}/dashboard/status-summary`;
      } else {
        locationParts[2] = targetId;
        newpath = locationParts.join('/');
      }
      locationParts[2] = targetId;
      return navigate(newpath);
    }
  };

  const handleItemExpansionToggle = (itemId, isExpanded, isProduct = false) => {
    if (isExpanded) {
      const newVals = [itemId];
      let productId = `${itemId}-products`;
      // expand org's products
      if (itemId.indexOf('-products') < 0) {
        newVals.push(`${itemId}-products`);
      } else {
        productId = itemId;
      }
      // collapse other products
      const existingResult = isProduct
        ? expanded
        : expanded.filter((val) => val.indexOf('-products') < 0 || val === productId);
      const result = uniq([...existingResult, ...newVals]);
      setExpanded(result);
    } else {
      setExpanded(expanded.filter((val) => val !== itemId && val !== `${itemId}-products`));
    }
  };

  const handleMenuOpen = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const renderTree = (nodes, nodeType = 'org') => (
    <TreeItem
      key={nodes._id}
      itemId={nodes._id}
      className={
        nodeType === 'org' && selectedOrgId === nodes._id
          ? 'selectedItem'
          : nodeType === 'product' && selectedProduct?._id === nodes._id
          ? 'selectedProduct'
          : ''
      }
      label={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            width: '100%'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {nodeType === 'product' ? (
              <>
                {nodes.type === PRODUCT_TYPES.JIRA ? (
                  <LiaJira style={{ marginLeft: '-0.8rem', paddingRight: '0.2rem' }} />
                ) : nodes.type === PRODUCT_TYPES.INTERNAL ? (
                  <ArchiveOutlinedIcon fontSize="0.5rem" sx={{ ml: -1.5, pr: 0.5 }} />
                ) : (
                  <Inventory2OutlinedIcon fontSize="0.5rem" sx={{ ml: -1.5, pr: 0.5 }} />
                )}
                <Typography
                  color={'grey.600'}
                  variant={'subtitle2'}
                  sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                >
                  {nodes.product_name}
                </Typography>
              </>
            ) : (
              <Typography
                variant={'body1'}
                sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
              >
                {nodes.name}
              </Typography>
            )}
          </div>
          {selectedOrgId === nodes._id && (user.isRoot || user.isAdmin) && (
            <ButtonGroup variant="text" aria-label="Controls button group">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(nodes);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openDeleteDialog(nodes);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleMenuOpen(e);
                }}
              >
                <PlusIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOpen(nodes, true);
                    handleMenuClose();
                  }}
                >
                  <CorporateFareSharpIcon fontSize="1rem" sx={{ pr: 0.5 }} /> New Organization
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigateToId({ org_id: nodes.org_id }, 'product');
                    handleMenuClose();
                  }}
                >
                  <Inventory2OutlinedIcon fontSize="1rem" sx={{ pr: 0.5 }} />
                  New Product
                </MenuItem>
              </Menu>
            </ButtonGroup>
          )}
        </div>
      }
      onClick={(event) => handleNodeClick(event, nodes, nodeType)}
    >
      {nodeType !== 'product' && (aiProducts ?? []).some((prod) => prod.org_id === nodes._id) && (
        <TreeItem
          itemId={`${nodes._id}-products`}
          className={selectedProduct?.org_id === nodes._id ? 'selectedProduct' : ''}
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '100%'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Inventory2OutlinedIcon fontSize="0.5rem" sx={{ pr: 0.5 }} />
                <Typography
                  color={'grey.600'}
                  variant={'subtitle2'}
                  sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                >
                  Products
                </Typography>
              </div>
            </div>
          }
          onClick={(e) => {
            e.stopPropagation();
            const id = `${nodes._id}-products`;
            const index = expanded.indexOf(id);
            if (index < 0) {
              setExpanded([...expanded, id]);
            } else {
              setExpanded(expanded.filter((val) => val !== id));
            }
          }}
        >
          {(aiProducts ?? [])
            .filter((o) => o.org_id === nodes._id)
            .map((node) => renderTree(node, 'product'))}
        </TreeItem>
      )}

      {nodes.children && typeof nodes.children === 'object'
        ? Object.values(nodes.children).map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Box sx={{ minHeight: 352, maxWidth: 400 }}>
          <SimpleTreeView
            expandedItems={expanded}
            selectedItems={selectedOrgId ?? null}
            expansionTrigger="iconContainer"
            onItemExpansionToggle={(e, itemId, isExpanded) => {
              handleItemExpansionToggle(itemId, isExpanded);
            }}
          >
            {treeData.map((data) => renderTree(data))}
          </SimpleTreeView>
        </Box>
      </Stack>
      {open && (
        <OrgCreateEdit
          open={open}
          handleClose={handleClose}
          saveOrg={saveOrg}
          org={selectedNode}
          hideParent={true}
        />
      )}
    </React.Fragment>
  );
};

export default TreeHierarchicalList;
