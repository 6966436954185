import { Box, Typography } from '@mui/material';
import scrybLogo from 'assets/img/scryb_logo_lg_blue.png';

const ErrorFallBackComponent = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src={scrybLogo}
        alt="scryb logo"
        style={{
          width: '30rem',
          maxWidth: '80svw',
          height: 'auto',
          margin: '1rem',
          marginBottom: '3rem'
        }}
      />
      <Typography color="grey.600" variant="h5" gutterBottom>
        Sorry, an unexpected error has occurred
      </Typography>
      {/* <Typography color="error" variant="body1" gutterBottom>
        {`${error?.name}: ${error.message}`}
      </Typography> */}
      <a href="/">Back To Home Page</a>
    </Box>
  );
};

export default ErrorFallBackComponent;
