import { createContext, useContext, useState } from 'react';
import ConfirmationDialog from 'components/dialog/confirmation-dialog';

const ConfirmDialogContext = createContext();

export const ConfirmDialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    open: false,
    title: '',
    body: '',
    onConfirm: () => { },
    onCancel: () => { }
  });

  const openConfirmDialog = (config) =>
    setDialogState({
      ...config,
      open: true,
      onConfirm: config.onConfirm || (() => { }),
      onCancel: config.onCancel || (() => { })
    });

  const closeConfirmDialog = () => setDialogState((prevState) => ({ ...prevState, open: false }));

  return (
    <ConfirmDialogContext.Provider value={{ openConfirmDialog, closeConfirmDialog, dialogState }}>
      {children}
      <ConfirmationDialog
        {...dialogState}
        onConfirm={() => {
          dialogState.onConfirm();
          closeConfirmDialog();
        }}
        onCancel={() => {
          dialogState.onCancel();
          closeConfirmDialog();
        }}
      />
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = () => {
  const context = useContext(ConfirmDialogContext);
  if (!context) throw new Error('useDialog must be used within a ConfirmDialogProvider');
  return context;
};
