import { useQuery } from '@tanstack/react-query';
import NodeTreeGraph from 'components/d3/node-tree-graph/node-tree-graph';
import { useState } from 'react';
import { riskService } from 'services/risk.service';
import RiskReviewNodeGraphDialog from './risk-review-node-graph-dialog';

const modalGraphConfig = {
  width: 1000,
  height: 800,
  isPreview: false,
  textSize: '1.25rem',
  tipPosition: 'ne'
};

const RiskReviewNodeGraphIndex = ({ productId, targetNode }) => {
  const [open, setOpen] = useState(false);

  const { data: treeData } = useQuery({
    queryKey: ['appRequirementsNodeData', productId, targetNode],
    queryFn: async () =>
      await riskService.getAppRequirementsNodeDataByProduct(productId, targetNode),
    enabled: !!productId && !!targetNode
  });

  return (
    <>
      {treeData && targetNode && (
        <NodeTreeGraph
          treeData={treeData}
          targetNode={targetNode}
          config={{ isPreview: true }}
          onExpandClick={() => setOpen(true)}
        />
      )}
      <RiskReviewNodeGraphDialog
        treeData={treeData}
        targetNode={targetNode}
        config={modalGraphConfig}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default RiskReviewNodeGraphIndex;
