import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import scrybLogo from 'assets/img/scryb_logo_lg_blue.png';

const NotFoundIndex = () => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src={scrybLogo}
        alt="scryb logo"
        style={{
          width: '30rem',
          maxWidth: '80svw',
          height: 'auto',
          margin: '1rem',
          marginBottom: '3rem'
        }}
      />
      <Typography variant="h4" gutterBottom>
        404 Page Not Found
      </Typography>
      <Link to={'/'}>Go Home</Link>
    </Box>
  );
};

export default NotFoundIndex;
