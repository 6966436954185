import { httpService } from './http.service.js';

const ENDPOINT = 'root-org';

export const rootOrgService = {
  getAll,
  getById,
  save,
  remove,
  getEmptyOrg
};

async function getAll() {
  return await httpService.get(`${ENDPOINT}/all`);
}

async function getById(rootOrgId) {
  return await httpService.get(`${ENDPOINT}/${rootOrgId}`);
}

async function remove(rootOrgId) {
  return await httpService.delete(`${ENDPOINT}/${rootOrgId}`);
}

async function save(org) {
  if (org._id) {
    return await httpService.put(`${ENDPOINT}/${org._id}`, org);
  }
  return await httpService.post(`${ENDPOINT}`, org);
}

function getEmptyOrg() {
  return {
    name: ''
  };
}

// const items = [
//
//     {
//       id: 2,
//       name: "Intel",
//       logoUrl: "https://logos-world.net/wp-content/uploads/2021/09/Intel-Logo-2006-2020-700x394.png"
//     },
//     {
//       id: 3,
//       name: "Apple",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
//     },
//     {
//       id: 4,
//       name: "Microsoft",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
//     },
//     {
//       id: 5,
//       name: "Google",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
//     },
//     {
//       id: 6,
//       name: "Amazon",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
//     },
//     {
//       id: 7,
//       name: "Facebook",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
//     },
//     {
//       id: 8,
//       name: "Samsung",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/2/24/Samsung_Logo.svg"
//     },
//     {
//       id: 9,
//       name: "Tesla",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png"
//     },
//     {
//       id: 10,
//       name: "IBM",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/5/51/IBM_logo.svg"
//     }
//   ];
