import { Check } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material';

const RiskReviewNameForm = ({
  defaultName = '',
  disabled = false,
  onNameChange = () => null,
  onNameSubmit = () => null
}) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      width="100%"
      onSubmit={(e) => {
        e.preventDefault();
        onNameSubmit(e);
      }}
    >
      <FormControl color="primary" variant="standard" required fullWidth>
        <InputLabel htmlFor="risk-review-display-name-input">Display Name</InputLabel>
        <Input
          id="risk-review-display-name-input"
          name="displayName"
          autoFocus
          defaultValue={defaultName}
          onChange={onNameChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                color="primary"
                variant="outlined"
                type="submit"
                title="Submit Name"
                disabled={disabled}
              >
                <Check color="primary" />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>Provide a name that others will see you as.</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RiskReviewNameForm;
