import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Paper, Skeleton, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import OrgCreateEdit from 'components/org/org-create-edit';
import { useAuth } from 'contexts/auth-context';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { rootOrgService } from 'services/root-org.service';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Home = () => {
  const [open, setOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const { user } = useAuth();

  const queryClient = useQueryClient();

  const { data: rootOrgs = [], isPending } = useQuery({
    queryKey: ['rootOrgs'],
    queryFn: async () => await rootOrgService.getAll(),
    enabled: user.isRoot
  });

  const { mutate: saveMutate, isPending: isPendingSave } = useMutation({
    mutationFn: (rootOrg) => rootOrgService.save(rootOrg),
    onSuccess: () => queryClient.invalidateQueries(['rootOrgs'])
  });

  const handleOpen = (org = null) => {
    setSelectedOrg(org);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrg(null);
  };

  const saveOrg = async (org) => {
    if (isPendingSave) {
      return;
    }
    if (selectedOrg) {
      org._id = selectedOrg._id;
    }
    await saveMutate(org);
    handleClose();
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className={className}
        style={{ ...style, display: 'block', color: 'black', right: '-1.5rem' }}
        onClick={onClick}
      />
    );
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIos
        className={className}
        style={{ ...style, display: 'block', color: 'black', left: '-1.5rem' }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true, // Ensure arrows are enabled
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <>
      <Box style={{ maxWidth: '85vw', margin: '6.25rem auto', minHeight: '12.5rem' }}>
        {!isPending &&
          (rootOrgs.length > 0 ? (
            <Slider {...settings}>
              {rootOrgs.map((item) => (
                <div key={item._id}>
                  <Item item={item} />
                </div>
              ))}
            </Slider>
          ) : (
            <div>No organizations available.</div>
          ))}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ width: '12rem', maxWidth: '90vw' }}
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Add Organization
        </Button>
      </Box>

      <OrgCreateEdit
        open={open}
        handleClose={handleClose}
        saveOrg={saveOrg}
        org={selectedOrg}
        hideParent={true}
      />
    </>
  );
};

const Item = ({ item }) => {
  return (
    <Paper
      elevation={0}
      style={{
        padding: '0.75rem',
        textAlign: 'center',
        width: '6.25rem',
        height: '10rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.75rem'
      }}
    >
      <Tooltip title={item.name}>
        <Link to={`/compliance/${item._id}/dashboard`}>
          {item.logoUrl ? (
            <img
              src={item.logoUrl}
              alt={item.name}
              style={{
                maxWidth: '80%',
                maxHeight: '8rem',
                minHeight: '4rem',
                objectFit: 'contain'
              }}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  inset: 0,
                  textAlign: 'center',
                  paddingTop: '1.5rem',
                  paddingBottom: 0
                }}
              >
                {item.name}
              </Typography>
              <Skeleton
                variant="rounded"
                animation={false}
                width={'8rem'}
                height={'5rem'}
                title={item.name}
              />
            </Box>
          )}
        </Link>
      </Tooltip>
    </Paper>
  );
};

export default Home;
