import { Box, ThemeProvider } from '@mui/material';
import Header from 'components/header/header';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import './App.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from 'pages/home';
import NotFoundIndex from 'pages/errors/not-found-index';
import SignIn from 'pages/sign-in'; // Import additional pages as needed

import { createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import ComplianceRiskIndex from 'components/compliance/risk/compliance-risk-index';
import RiskReviewHostIndex from 'components/risk-review/host/risk-review-host-index';
import RiskReviewLandingIndex from 'components/risk-review/landing/risk-review-landing-index';
import RiskReviewerIndex from 'components/risk-review/reviewer/risk-reviewer-index';
import { useAuth } from 'contexts/auth-context';
import { RISK_PRODUCT_TYPES } from 'lib/constants/compliance';
import { STALE_TIME } from 'lib/constants/query';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import AccessRoleRoutes from 'pages/wrappers/access-role-routes';
import AdminIndex from 'pages/admin-index';
import ComplianceIndex from 'pages/compliance-index';
import ConditionalRoutes from 'pages/wrappers/conditional-routes';
import ErrorRouteElement from 'pages/errors/error-route-element';
import ProtectedRoute from 'pages/wrappers/protected-route';
import RootUserRoute from 'pages/root-user-route';
import { lazy, useEffect, useRef } from 'react';

const AdminProfileIndex = lazy(() => import('components/admin/admin-profile/admin-profile-index'));
const AdminUserIndex = lazy(() => import('components/admin/admin-users/admin-user-index'));
const AdminSettingsIndex = lazy(() =>
  import('components/admin/admin-settings/admin-settings-index')
);

const ComplianceAIProduct = lazy(() => import('components/ai-product/compliance-ai-product'));
const ComplianceAIProducts = lazy(() => import('components/ai-product/compliance-ai-products'));
const ComplianceActions = lazy(() => import('components/compliance/actions/compliance-actions'));
const ComplianceCustomPolicies = lazy(() =>
  import('components/compliance/custom-policies/compliance-custom-policies')
);
const ComplianceCustomPolicy = lazy(() =>
  import('components/compliance/custom-policies/compliance-custom-policy')
);
const DashboardActionTotal = lazy(() =>
  import('components/compliance/dashboard/dashboard-action/dashboard-action-total')
);
const DashboardIndex = lazy(() => import('components/compliance/dashboard/dashboard-index'));

const DashboardProductsType = lazy(() =>
  import('components/compliance/dashboard/dashboard-product/dashboard-products-type')
);
const CompalianceDocuments = lazy(() =>
  import('components/compliance/documents/compliance-documents')
);
const ComplianceCanada = lazy(() =>
  import('components/compliance/dynamic-policies/compliance-canada')
);
const ComplianceEuAct = lazy(() => import('components/compliance/dynamic-policies/compliance-eu'));
const ComplianceUK = lazy(() => import('components/compliance/dynamic-policies/compliance-uk'));
const ComplianceRMF = lazy(() => import('components/compliance/rmf/compliance-rmf'));
const ComplianceUsers = lazy(() => import('components/compliance/users/compliance-users'));
const DashboardStatusIndex = lazy(() =>
  import('components/compliance/dashboard/dashboard-status/dashboard-status-index')
);
const DashboardUsersIndex = lazy(() =>
  import('components/compliance/dashboard/dashboard-user/dashboard-user-index')
);

const RiskReviewIndex = lazy(() => import('pages/risk-review-index'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME
    }
  }
});

const riskColors = ['#40c000', '#8cca13', '#d8d526', '#ffb027', '#ff5814', '#ff0000'];
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#64b5f6',
      main: '#1976D2',
      dark: '#0d46a1',
      contrastText: '#fff'
    },
    secondary: {
      light: '#bc97e9',
      main: '#7619d2',
      dark: '#ba000d',
      contrastText: '#fff'
    },
    complementary: {
      light: '#e5ad54',
      main: '#d27619',
      dark: '#bf5213',
      contrastText: '#fff'
    },
    risks: riskColors,
    risksObj: {
      low: riskColors[0],
      medium: riskColors[2],
      high: riskColors[5]
    }
  }
});

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { user, signOut, refreshToken } = useAuth();
  const msgKeyRef = useRef(null);

  useEffect(() => {
    if (user && user.token) {
      refreshToken().catch((err) => {
        console.error('refresh error:', String(err));
        // unique case with an unprotected route but requires host to be signed in
        if (location.pathname.indexOf('/risk-review') > -1) {
          return signOut();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.status === 401 || error?.response?.status === 401) {
        if (location.pathname !== '/signin') {
          // TODO figure out how this works with react-query
          return signOut();
        }
      }

      if (msgKeyRef.current) {
        closeSnackbar(msgKeyRef.current);
      }
      error = error.response ? error.response : error;
      const statusText = error.name ?? error.data?.name ?? error.statusText ?? error.status ?? '';
      msgKeyRef.current = enqueueSnackbar(
        `${statusText ? statusText + ':' : ''} ${
          error.data?.message ?? error.message ?? 'Unknown Error'
        }`,
        { variant: 'error' }
      );

      return Promise.reject(error);
    }
  );

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Box
            className="App"
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100svh' }}
          >
            <Header />
            <Routes>
              <Route
                index
                element={
                  <RootUserRoute>
                    <Home />
                  </RootUserRoute>
                }
                errorElement={<ErrorRouteElement />}
              />

              <Route path="/admin" element={<AdminIndex />}>
                <Route path="users" element={<AdminUserIndex />} />
                <Route path="profile" element={<AdminProfileIndex />} />
                <Route path="settings" element={<AdminSettingsIndex />} />
              </Route>

              <Route
                path="/compliance/:orgId"
                element={
                  <ProtectedRoute>
                    <AccessRoleRoutes user={user} redirectTo={{ pathname: '/not-found' }} />
                  </ProtectedRoute>
                }
                errorElement={<ErrorRouteElement />}
              >
                <Route element={<ComplianceIndex />}>
                  <Route path="dashboard" element={<DashboardIndex />}>
                    {/* Nested products route under dashboard */}
                    <Route path="status-summary" element={<DashboardStatusIndex />} />
                    <Route path="product-types" element={<DashboardProductsType />} />
                    <Route path="top-actions" element={<DashboardActionTotal />} />
                    <Route path="top-users" element={<DashboardUsersIndex />} />

                    <Route
                      index
                      element={
                        <Navigate to={{ ...location, pathname: 'status-summary' }} replace />
                      }
                    />
                  </Route>
                  <Route path="users" element={<ComplianceUsers />} />
                  <Route path="nist" element={<ComplianceRMF />} />

                  <Route
                    element={
                      <ConditionalRoutes
                        isAllowed={!searchParams.has('prId')}
                        redirectTo={{
                          ...location,
                          pathname: `product/${searchParams.get('prId')}`
                        }}
                      />
                    }
                  >
                    <Route path="products" element={<ComplianceAIProducts />} />
                  </Route>
                  <Route path="product/:productId?" element={<ComplianceAIProduct />} />
                  <Route path="actions" element={<ComplianceActions />} />
                  <Route path="documents" element={<CompalianceDocuments />} />
                  <Route
                    element={
                      <ConditionalRoutes
                        isAllowed={RISK_PRODUCT_TYPES.indexOf(searchParams.get('prType')) > -1}
                        redirectTo={{
                          ...location,
                          pathname: `product/${searchParams.get('prId')}`
                        }}
                      />
                    }
                  >
                    <Route path="risk" element={<ComplianceRiskIndex />} />
                  </Route>
                  <Route
                    index
                    element={<Navigate to={{ ...location, pathname: 'users' }} replace />}
                  />
                  {/* Dynamic Compliance Routes */}
                  <Route path="canada" element={<ComplianceCanada />} />
                  <Route path="eu" element={<ComplianceEuAct />} />
                  <Route path="uk" element={<ComplianceUK />} />
                  <Route path="custom-policies" element={<ComplianceCustomPolicies />} />
                  <Route
                    path="custom-policy/:policyType/:policyId?"
                    element={<ComplianceCustomPolicy />}
                  />
                  {/* Dynamic Compliance Routes END */}
                  <Route
                    index
                    element={<Navigate to={{ ...location, pathname: 'dashboard' }} replace />}
                  />
                </Route>
              </Route>
              <Route
                path="/risk-review/:sessionId"
                element={<RiskReviewIndex />}
                errorElement={<ErrorRouteElement />}
              >
                <Route path="landing" element={<RiskReviewLandingIndex />} />
                <Route path="host" element={<RiskReviewHostIndex />} />
                <Route path="reviewer" element={<RiskReviewerIndex />} />
                <Route
                  index
                  element={<Navigate to={{ ...location, pathname: 'landing' }} replace />}
                />
              </Route>
              <Route path="/signin" element={<SignIn />} errorElement={<ErrorRouteElement />} />
              <Route path="*" element={<NotFoundIndex />} />
              {/* Add more routes as needed */}
            </Routes>
          </Box>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
