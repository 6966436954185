import { httpService } from './http.service.js';

const ENDPOINT = 'app-requirements/';

export const riskService = {
  getAppRequirementsSection,
  getAppRequirementsDataSection,
  getAppRequirementsNodeDataByProduct,
  updateAppRequirementsOverrides
};

async function getAppRequirementsSection(productId, section = 'ha') {
  return await httpService.get(`${ENDPOINT}/product/${productId}/section/${section}`);
}

async function getAppRequirementsDataSection(productId, section = 'ha') {
  return await httpService.get(`${ENDPOINT}/data/${productId}/section/${section}`);
}

async function getAppRequirementsNodeDataByProduct(productId, nodeId) {
  return await httpService.get(`${ENDPOINT}/product/${productId}/node/${nodeId}`);
}

async function updateAppRequirementsOverrides(rowId, overrides) {
  return await httpService.put(`${ENDPOINT}/data/overrides/${rowId}`, overrides);
}
