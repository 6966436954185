import { googleLogout } from '@react-oauth/google';
import axios from 'axios';
import InvalidTokenError from 'lib/errors/InvalidTokenError';
import { cloneDeep } from 'lodash';
import { createContext, useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { httpService } from 'services/http.service';

export const USER_STORAGE_KEY = 'linesight-user';

const loadSavedUser = () => {
  const savedUser = localStorage.getItem(USER_STORAGE_KEY);
  if (savedUser) {
    const result = JSON.parse(savedUser);
    axios.defaults.headers.common['Authorization'] = `Bearer ${result.token}`;
    return result;
  }
  delete axios.defaults.headers.common['Authorization'];
  return null;
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(loadSavedUser);

  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams] = useSearchParams();

  const navigateFromSignIn = () => {
    let redirect = searchParams.get('r');
    if (redirect) {
      console.log(`redirecting to [${redirect}]`);
      return navigate(redirect.startsWith('/') ? redirect : '/' + redirect);
    }
    return navigate('/');
  };

  const navigateToSignIn = (hasRedirect) => {
    const query = hasRedirect ? `?r=${location.pathname}` : '';
    return navigate(`/signin${query}`);
  };

  const signIn = async (credential) => {
    try {
      const user = await httpService.post('auth/signin', {
        credential
      });
      if (user) {
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
        setUser(user);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

        if (location.pathname === '/signin') {
          return navigateFromSignIn();
        }
      } else {
        setUser(null);
        delete axios.defaults.headers.common['Authorization'];
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signOut = async (hasRedirect = true) => {
    // logout google
    try {
      googleLogout();
    } catch (err) {
      console.warn(err);
    }
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
    setUser(null);
    if (location.pathname !== '/signin') {
      navigateToSignIn(hasRedirect);
    }
    // clears weird state behavior
    window.location.reload();
  };

  const refreshToken = async () => {
    if (!user || !user.token) {
      throw new Error('No user to refresh');
    }
    if (user.exp < Date.now()) {
      throw new InvalidTokenError('Expired token');
    }

    const result = await httpService.post('auth/refresh', {
      email: user.email,
      token: user.token
    });

    // new token, update existing
    if (result.token && result.exp) {
      const newUser = cloneDeep(user);
      newUser.exp = result.exp;
      newUser.token = result.token;
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newUser));
      axios.defaults.headers.common['Authorization'] = `Bearer ${newUser.token}`;
      setUser(newUser);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, refreshToken, navigateFromSignIn, navigateToSignIn }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
