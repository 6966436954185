import { httpService } from './http.service.js';
const endpoint = 'admin';

export const adminService = {
  getAllUsers,

};

async function getAllUsers() {

  const users = await httpService.get(`${endpoint}/users`);
  console.log("admin users service front", users)
  return users
}
