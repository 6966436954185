import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

const defaultCheckboxes = [
  { name: 'nist', label: 'Nist', defaultChecked: true, disabled: true },
  { name: 'canada', label: 'Canada', defaultChecked: false, disabled: false },
  { name: 'eu', label: 'EU', defaultChecked: false, disabled: false },
  { name: 'uk', label: 'UK', defaultChecked: false, disabled: false },
  { name: 'custom-policies', label: 'Custom Policies', defaultChecked: false, disabled: false }
];

const OrgComplianceForm = ({
  selected = ['nist'],
  states = [],
  onSelectedChange = () => null,
  onStatesChange = () => null
}) => {
  const [stateOpts, setStateOpts] = useState([]);

  useEffect(() => {
    const fetchStateOpts = async () => {
      try {
        const newStateOpts = await fetch('/assets/json/states_titlecase.json').then((response) =>
          response.json()
        );
        setStateOpts(newStateOpts);
      } catch (err) {
        console.error(err);
      }
    };

    fetchStateOpts();
  }, []);

  const checkboxOpts = defaultCheckboxes.map((opt) => {
    return (
      <FormControlLabel
        key={opt.name}
        control={
          <Checkbox
            checked={opt.defaultChecked || selected.indexOf(opt.name) > -1}
            onChange={onSelectedChange}
            name={opt.name}
            disabled={opt.disabled}
          />
        }
        label={opt.label}
      />
    );
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl fullWidth sx={{ mt: 3 }} component="fieldset" margin="dense">
        <FormLabel component="legend">Compliance</FormLabel>
        <FormGroup row>{checkboxOpts}</FormGroup>
        <FormGroup row>
          <Autocomplete
            multiple
            options={stateOpts.map((s) => s.name)}
            freeSolo
            includeInputInList
            fullWidth
            value={states}
            onChange={(e, val) => onStatesChange(val)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ mt: 0 }}
                fullWidth
                variant="standard"
                label="States"
                placeholder="Type state name..."
              />
            )}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default OrgComplianceForm;
