import { Box, Typography } from '@mui/material';
import RiskReviewNameForm from 'components/risk-review/landing/risk-review-name-form';
import { useAuth } from 'contexts/auth-context';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

const RiskReviewLandingIndex = () => {
  const { user } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [selectedRisk, displayName, setDisplayName, isConnected, doPublish] = useOutletContext();
  const [defaultName] = useState(state?.displayName ?? user?.name ?? displayName ?? '');

  const onNameSubmit = (e) => {
    if (!isConnected) {
      // show error
      enqueueSnackbar('No MQTT Connection.', { variant: 'error' });
      return;
    }
    if (user?.email && user?.email === selectedRisk?.host) {
      return navigate({ ...location, state: { displayName }, pathname: '../host' });
    }
    return navigate({ ...location, state: { displayName }, pathname: '../reviewer' });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '75%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box sx={{ width: 'min(85%, 20rem)' }}>
        <Typography color="grey.700" variant="h4" sx={{ mb: 2 }}>
          Risk Review
        </Typography>
        <RiskReviewNameForm
          defaultName={defaultName}
          onNameChange={(e) => setDisplayName(e.target.value)}
          onNameSubmit={onNameSubmit}
          disabled={!displayName?.length}
        />
      </Box>
    </Box>
  );
};

export default RiskReviewLandingIndex;
