import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import scrybLogo from 'assets/img/scryb_logo_sm_blue.png';
import { useAuth } from 'contexts/auth-context';
import { APP_HEADER_HEIGHT } from 'lib/constants/app';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import UserMenu from './user-menu';

// 66a3e92218ec387a5b73a56f
const Header = () => {
  const { user, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // signout btn and avatar in navbar
  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuNav = (location) => {
    navigate(`/admin/${location}`);
    handleClose();
  };
  return (
    <AppBar position="sticky" sx={{ height: APP_HEADER_HEIGHT }}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          <img
            src={scrybLogo}
            alt="scryb logo"
            style={{ width: '8rem', marginRight: '0.5rem', paddingTop: '0.35rem' }}
          />
        </NavLink>
        {user?.email && user?.token && (
          <>
            <Avatar
              alt="Tracebox User"
              src={user?.picture}
              onClick={handleUserMenuClick}
              crossOrigin="anonymous"
              sx={{
                cursor: 'pointer'
              }}
            />
            <UserMenu
              anchorEl={anchorEl}
              onClose={handleClose}
              user={user}
              onLogout={() => signOut(false)}
              onMenuNav={handleMenuNav}
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
