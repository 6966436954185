import { httpService } from './http.service.js';

const endpoint = 'ai-product';

export const aiProductService = {
  getById,
  getByIdToArr,
  getAll,
  getAllByOrgs,
  remove,
  save
};

async function getById(_id) {
  if (!_id) {
    return null;
  }
  return await httpService.get(`${endpoint}/${_id}`);
}

async function getByIdToArr(_id) {
  if (!_id) {
    return null;
  }
  const result = await getById(_id);
  return [result];
}

async function getAll() {
  return await httpService.get(endpoint);
}

async function getAllByOrgs(orgList = []) {
  if (!orgList?.length) return [];
  const data = await getAll();
  return data.filter((product) => orgList.some((org) => org._id === product.org_id));
}

async function remove(_id) {
  return await httpService.delete(`${endpoint}/${_id}`);
}

async function save(product) {
  if (product._id) {
    return await httpService.put(`${endpoint}/${product._id}`, product);
  }
  return await httpService.post(`${endpoint}`, product);
}
