import { Box, Button, FormControl, Grid, Paper, Slider, Typography } from '@mui/material';
import { useState } from 'react';
import { RISK_SCALE_VALUES } from 'lib/constants/compliance';
import { has } from 'lodash';

const RiskReviewerForm = ({
  hasVoted = false,
  riskName = '',
  riskDescription = '',
  isPostMitigation = false,
  riskMitigation = '',
  onSubmitRiskVote = () => null
}) => {
  const [userRiskVote, setUserRiskVote] = useState({
    probability: 2,
    severity: 2,
    detectability: 2
  });

  const handleSliderChange = (field) => (event, value) => {
    setUserRiskVote((prevVote) => ({
      ...prevVote,
      [field]: value
    }));
  };

  const handleSubmitUserRiskVote = (event) => {
    event.preventDefault();
    onSubmitRiskVote(userRiskVote);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmitUserRiskVote}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: 2,
        my: 2,
        boxShadow: 4,
        borderRadius: 3,
        backgroundColor: '#f3f4f6'
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        {isPostMitigation ? 'Post-Mitigation Vote' : 'Hazordous Event Vote'}
      </Typography>
      <Typography variant="h6" align="left" gutterBottom>
        Risk name: {riskName}
      </Typography>
      <Typography variant="p" align="left" gutterBottom>
        {isPostMitigation
          ? 'Mitigation measures: ' + riskMitigation
          : 'Risk description: ' + riskDescription}
      </Typography>

      {['probability', 'severity', 'detectability'].map((field, index) => (
        <FormControl key={index} fullWidth>
          <Paper elevation={0} sx={{ pt: 1, backgroundColor: '#f1f3f5' }}>
            <Grid container alignItems="end" spacing={1}>
              <Grid item xs>
                <Typography
                  variant="subtitle1"
                  sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                  gutterBottom
                >
                  <Box>{field.charAt(0).toUpperCase() + field.slice(1)}</Box>
                  <Box color="grey.600">{RISK_SCALE_VALUES[userRiskVote[field] - 1].label}</Box>
                </Typography>
                <Slider
                  value={userRiskVote[field]}
                  onChange={handleSliderChange(field)}
                  min={1}
                  max={6}
                  step={1}
                  marks
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom color="primary">
                  {userRiskVote[field]}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </FormControl>
      ))}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={hasVoted}
        sx={{
          marginTop: 3,
          padding: '12px 0',
          fontSize: '1rem',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#3b82f6'
          }
        }}
      >
        {hasVoted ? 'Vote Submitted' : 'Vote'}
      </Button>
    </Box>
  );
};

export default RiskReviewerForm;
