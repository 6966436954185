import CloseIcon from '@mui/icons-material/Close'; // Import the CloseIcon
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { aiActorsService } from 'services/ai-actors.service';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const CreateEditUser = ({
  userToEdit,
  handleRolesChange,
  handleChange,
  handleSubmit,
  disabled
}) => {
  const [roles, setRoles] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false); // State to control Select's open state
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    getRoles();
  }, []);
  const getRoles = async () => {
    try {
      const roles = await aiActorsService.getRoles();
      setRoles(roles);
      console.log('roles', roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };
  const handleCloseSelect = () => {
    setSelectOpen(false);
  };
  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
      }}
    >
      {' '}
      <Stack
        spacing={3}
        sx={{ width: '100%', minWidth: 1000, maxHeight: '80vh', p: 3, overflowY: 'auto' }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {userToEdit._id ? 'Edit User' : 'New User'}
        </Typography>

        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 6 }}>
          {/* Personal Information */}
          <Stack spacing={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              Personal Information
            </Typography>
            <Box sx={{ display: 'flex', gap: 4 }}>
              {/* Profile picture section */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="profile-image-upload"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        handleChange({
                          target: {
                            name: 'picture',
                            value: e.target.result
                          }
                        });
                        setImageLoaded(false);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                <Box
                  sx={{
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    border: '2px solid #e0e0e0',
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#f5f5f5',
                    '&:hover': {
                      '& .upload-overlay': {
                        opacity: 1
                      }
                    }
                  }}
                >
                  {!imageLoaded && (
                    <AccountCircleIcon sx={{ transform: 'scale(5)', color: 'text.secondary' }} />
                  )}
                  {userToEdit.picture && (
                    <Box
                      component="img"
                      src={userToEdit.picture}
                      alt="Profile"
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: imageLoaded ? 'block' : 'none'
                      }}
                      onLoad={() => setImageLoaded(true)}
                      onError={() => {
                        setImageLoaded(true);
                      }}
                    />
                  )}
                  <Box
                    className="upload-overlay"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      bgcolor: 'rgba(0,0,0,0.5)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: 0,
                      transition: 'opacity 0.2s',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      document.getElementById('profile-image-upload').click();
                    }}
                  >
                    <Typography variant="caption" sx={{ color: 'white' }}>
                      {userToEdit.picture ? 'Update Photo' : 'Add Photo'}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Name fields */}
              <Stack spacing={2} sx={{ flex: 1 }}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={userToEdit.firstName || ''}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={userToEdit.lastName || ''}
                  onChange={handleChange}
                  required
                />
              </Stack>
            </Box>

            <TextField
              label="Email"
              name="email"
              type="email"
              value={userToEdit.email || ''}
              onChange={handleChange}
              required
            />
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                name="role"
                value={userToEdit?.role || ''}
                onChange={handleChange}
                required
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="super-admin">Super-Admin</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Phone"
              name="phone"
              value={userToEdit.phone || ''}
              onChange={handleChange}
            />
            {/* Roles Section */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                Roles & Permissions
              </Typography>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel>AI Roles</InputLabel>
                  <Select
                    label="AI Roles"
                    name="aiActors"
                    multiple
                    value={userToEdit?.aiActors || []}
                    onChange={handleRolesChange}
                    open={selectOpen}
                    onOpen={() => setSelectOpen(true)}
                    onClose={handleCloseSelect}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 400, position: 'relative' }
                      }
                    }}
                  >
                    {roles?.map((actor) => (
                      <MenuItem key={actor} value={actor}>
                        <Checkbox checked={userToEdit?.aiActors?.indexOf(actor) > -1} />
                        <ListItemText primary={actor} />
                      </MenuItem>
                    ))}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        top: 0,
                        right: 0
                      }}
                    >
                      <IconButton onClick={handleCloseSelect}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          </Stack>

          {/* Work Information */}
          <Stack spacing={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              Work Information
            </Typography>
            <TextField
              label="Organization ID"
              name="orgId"
              value={userToEdit.orgId || ''}
              onChange={handleChange}
              disabled
            />
            <TextField
              label="Department"
              name="department"
              value={userToEdit.department || ''}
              onChange={handleChange}
            />
            <TextField
              label="Job Title"
              name="jobTitle"
              value={userToEdit.jobTitle || ''}
              onChange={handleChange}
            />
            <TextField
              label="Work Email"
              name="workEmail"
              type="email"
              value={userToEdit.workEmail || ''}
              onChange={handleChange}
            />
            <TextField
              label="Work Phone"
              name="workPhone"
              value={userToEdit.workPhone || ''}
              onChange={handleChange}
            />
            <TextField
              label="Location"
              name="location"
              value={userToEdit.location || ''}
              onChange={handleChange}
            />
          </Stack>
        </Box>
        {/* System Information */}
        <Box sx={{ mt: 2, width: '100%' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
            System Information
          </Typography>
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label="Last Updated"
              value={
                userToEdit.lastUpdated
                  ? new Date(userToEdit.lastUpdated.$date).toLocaleString()
                  : ''
              }
              disabled
            />
            <TextField
              fullWidth
              label="Created At"
              value={
                userToEdit.createdAt ? new Date(userToEdit.createdAt.$date).toLocaleString() : ''
              }
              disabled
            />
          </Stack>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={disabled}
            size="large"
          >
            {userToEdit?._id ? 'Update User' : 'Create User'}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateEditUser;
