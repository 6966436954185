import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NodeTreeGraph from 'components/d3/node-tree-graph/node-tree-graph';

const RiskReviewNodeGraphDialog = ({
  treeData = [],
  targetNode = null,
  open = false,
  config = {},
  onClose = () => null
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle
        color="primary"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <span>{targetNode} Traceability Graph</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{ '& svg': { width: '1.5rem !important', height: '1.5rem !important' } }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {open && treeData?.length > 0 && targetNode && (
          <NodeTreeGraph treeData={treeData} targetNode={targetNode} config={config} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RiskReviewNodeGraphDialog;
