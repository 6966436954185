import { httpService } from './http.service.js';
const endpoint = 'users';

export const usersService = {
  getAccessRoles,
  getUsers,
  getUsersWithChildren,
  getUserById,
  remove,
  save
};

async function getAccessRoles() {
  return await httpService.get(`${endpoint}/access-roles`);
}

async function getUsers(orgId) {
  if (!orgId) {
    return null;
  }
  return await httpService.get(`${endpoint}/org/${orgId}`);
}

async function getUsersWithChildren(orgId) {
  if (!orgId) {
    return null;
  }
  return await httpService.get(`${endpoint}/orgs/${orgId}`);
}

async function getUserById(userId) {
  if (!userId) {
    return null;
  }
  return await httpService.get(`${endpoint}/${userId}`);
}

async function remove(_id) {
  return await httpService.delete(`${endpoint}/${_id}`);
}

async function save(user) {
  let savedUser;
  if (user._id) {
    console.log('update user');
    savedUser = await httpService.put(`${endpoint}/${user._id}`, user);
  } else {
    console.log('create user');
    savedUser = await httpService.post(endpoint, user);
  }
  return savedUser;
}
