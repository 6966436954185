import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PRODUCT_TYPES } from 'lib/constants/compliance';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jiraService } from 'services/jira.service';
import { riskService } from 'services/risk.service';

import { CoPresent } from '@mui/icons-material';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { useAuth } from 'contexts/auth-context';
import { enqueueSnackbar } from 'notistack';
import { mqttSessionService } from 'services/mqtt-sessions.service';
import styles from './compliance-risk-index.module.css';
import RiskAssessmentDialog from './risk-assessment/risk-assessment';

const getRiskScoreClass = (params) => {
  const score = params.value;

  if (score >= 1 && score <= 23) {
    return styles['risk-level-low'];
  } else if (score >= 24 && score <= 35) {
    return styles['risk-level-med'];
  } else if (score >= 36 && score <= 125) {
    return styles['risk-level-high'];
  }
  return '';
};

const getNumericColClass = (params) => {
  const prob = params.value;
  if (prob >= 1 && prob <= 6) return styles[`risk-level-${prob}`];
  if (prob > 6) return styles['risk-level-high'];
  return '';
};

const CustomToolbar = ({ onStartNewRiskSession, isCreateRiskSessionDisabled = false }) => {
  return (
    <GridToolbarContainer>
      <Stack direction="row" spacing={1}>
        <Tooltip title="Start a new risk review session">
          <span>
            <Button
              variant="outlined"
              size="small"
              startIcon={<CoPresent />}
              disabled={isCreateRiskSessionDisabled}
              onClick={onStartNewRiskSession}
            >
              New Risk Session
            </Button>
          </span>
        </Tooltip>
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector slotProps={{ tooltip: { title: 'Change density' } }} />
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter sx={{ pl: 2 }} />
    </GridToolbarContainer>
  );
};

const ComplianceRiskIndex = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const prId = searchParams.get('prId');
  const prType = searchParams.get('prType');
  const { user } = useAuth();

  const [selectedRows, setSelectedRows] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);

  // TODO create wrapper function that handles which data to fetch, reduce queries to one
  const { data: risksInternal = [], isPending: isPendingInternal } = useQuery({
    queryKey: ['risksData', prId],
    queryFn: async () => await riskService.getAppRequirementsDataSection(prId),
    enabled: !!prId && prType === PRODUCT_TYPES.INTERNAL
  });

  const { data: risksJira = [], isPending: isPendingJira } = useQuery({
    queryKey: ['risksJira', prId],
    queryFn: async () => await jiraService.getAllRisksByProduct(prId),
    enabled: !!prId && prType === PRODUCT_TYPES.JIRA
  });

  const risks = useMemo(() => {
    if (prType === PRODUCT_TYPES.INTERNAL) return risksInternal;
    if (prType === PRODUCT_TYPES.JIRA) return risksJira;
    return [];
  }, [risksInternal, risksJira, prType]);

  const isPending = useMemo(() => {
    if (prType === PRODUCT_TYPES.INTERNAL) return isPendingInternal;
    if (prType === PRODUCT_TYPES.JIRA) return isPendingJira;
    return false;
  }, [isPendingInternal, isPendingJira, prType]);

  const selectedRisk = useMemo(() => {
    if (selectedRows.length !== 1) return null;
    return risks.find((risk) => risk._id === selectedRows[0]);
  }, [risks, selectedRows]);
  console.log('SELECTEDRISk', selectedRisk);

  const { mutate: mutateCreateRiskSession, isPending: isPendingRiskSession } = useMutation({
    mutationFn: (data) => mqttSessionService.createRiskSession(data),
    onSuccess: (sessionId) => navigate(`/risk-review/${sessionId}/host`),
    onError: () => enqueueSnackbar('Failed to create risk session', { variant: 'error' })
  });

  const createRiskSession = () => {
    if (prType !== PRODUCT_TYPES.INTERNAL) {
      enqueueSnackbar('Risk Session creation not available for this product type', {
        variant: 'error'
      });
      return;
    }
    if (isPendingRiskSession) {
      enqueueSnackbar('Risk Session creation already in progress', { variant: 'warning' });
      return;
    }

    const filteredRisks = risks //
      .filter((risk) => selectedRows.indexOf(risk._id) > -1) //
      .reverse();

    const data = {
      prId,
      host: user.email,
      risks: filteredRisks
    };
    mutateCreateRiskSession(data);
  };
  const handleHANumberClick = (params) => {
    if (prType !== PRODUCT_TYPES.INTERNAL) {
      return;
    }
    setSelectedRows([params.row._id]);
    setDialogOpen(true);
  };
  const columns = [
    {
      field: 'HA Number',
      renderCell: ({ row, value }) => (
        <Typography
          sx={{
            color: 'primary.main',
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          onClick={(e) => handleHANumberClick({ row, value })}
        >
          {value}
        </Typography>
      )
    },
    {
      field: 'Text for HA ref selection',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'Reference',
      minWidth: 100
    },
    {
      field: 'Type of hazard',
      minWidth: 150
    },
    {
      field: 'Hazard categorization',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'Hazardous Event or Situation',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'Probability',
      flex: 0,
      type: 'number',
      cellClassName: getNumericColClass
    },
    {
      field: 'Severity',
      flex: 0,
      type: 'number',
      cellClassName: getNumericColClass
    },
    {
      field: 'Detectability',
      flex: 0,
      type: 'number',
      cellClassName: getNumericColClass
    },
    {
      field: 'Risk Score',
      flex: 0,
      type: 'number',
      cellClassName: getRiskScoreClass
    },
    {
      field: 'Mitigation Measures',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'PFMEA?',
      minWidth: 100,
      flex: 0
    },
    {
      field: 'Mitigation Adds New Risk',
      flex: 0
    },
    {
      field: 'Probability (FM)',
      headerName: 'Probability',
      minWidth: 120,
      flex: 0,
      type: 'number',
      cellClassName: getNumericColClass
    },
    {
      field: 'Severity (FM)',
      headerName: 'Severity',
      minWidth: 120,
      flex: 0,
      type: 'number',
      cellClassName: getNumericColClass
    },
    {
      field: 'Detectability (FM)',
      headerName: 'Detectability',
      minWidth: 120,
      flex: 0,
      type: 'number',
      cellClassName: getNumericColClass
    },
    {
      field: 'Risk Score (FM)',
      headerName: 'Risk Score',
      minWidth: 120,
      flex: 0,
      type: 'number',
      cellClassName: getRiskScoreClass
    }
  ];
  return (
    <>
      <div
        style={{
          width: '100%',
          height: 'calc(100svh - 10.5rem)',
          paddingBottom: '1rem'
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={risks}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[5, 10, 25, 50, 100, { label: 'All', value: -1 }]}
          pagination
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnResize
          onRowSelectionModelChange={(newSelectedRows) => {
            setSelectedRows(newSelectedRows);
          }}
          rowSelectionModel={selectedRows}
          getRowHeight={() => 'auto'}
          loading={isPending}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              onStartNewRiskSession: createRiskSession,
              isCreateRiskSessionDisabled:
                prType !== PRODUCT_TYPES.INTERNAL || isPendingRiskSession || selectedRows.length < 1
            }
          }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: ' none'
            },
            '& .MuiDataGrid-cell': {
              minHeight: 40,
              maxHeight: 120
            }
          }}
        />
      </div>
      <RiskAssessmentDialog
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSelectedRows([]);
        }}
        data={selectedRisk}
        prId={prId}
      />
    </>
  );
};

export default ComplianceRiskIndex;
