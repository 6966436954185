import { httpService } from './http.service.js';

const ENDPOINT = 'jira';

export const jiraService = {
  getAllProjects,
  getAllRisksByProduct
};

async function getAllProjects() {
  return await httpService.get(`${ENDPOINT}/projects/all`);
}

async function getAllRisksByProduct(productId) {
  return await httpService.get(`${ENDPOINT}/product/${productId}/risks`);
}
