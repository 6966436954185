import { Grid, Paper, TextField, Typography } from '@mui/material';
import RiskAssessmentScore from 'components/compliance/risk/risk-assessment/risk-assessment-score/risk-assessmemt-score';
import RiskAssessmentForm from 'components/compliance/risk/risk-assessment/risk-assessment-form';
import RiskAssessmentInfo from 'components/compliance/risk/risk-assessment/risk-assessment-info';

import { useEffect, useMemo, useReducer } from 'react';

const initialState = {
  riskNumber: '',
  source: 'AI/employee/role',
  productVersion: '',
  mitigationByAI: '',
  mitigationByHuman: '',
  mitigationStatus: 'A',
  validationNum: '',
  validationStatus: '',
  verificationNum: '',
  verificationStatus: '',
  notes: '',
  scores: {
    before: {
      ai: { A: 0, B: 0, C: 0, D: 0 }
    },
    afterMitigation: {
      ai: { A: 0, B: 0, C: 0, D: 0 }
    }
  }
};

const ACTIONS = {
  UPDATE_FIELD: 'UPDATE_FIELD',
  SET_INITIAL_DATA: 'SET_INITIAL_DATA'
};

const formReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_FIELD:
      return {
        ...state,
        [action.field]: action.value
      };
    case ACTIONS.SET_INITIAL_DATA:
      return {
        ...state,
        ...action.data,
        scores: action.scores
      };
    default:
      return state;
  }
};

const RiskReviewHostForm = ({ selectedRisk }) => {
  const [formData, dispatch] = useReducer(formReducer, initialState);

  const data = useMemo(() => {
    return selectedRisk?.risk ?? {};
  }, [selectedRisk]);

  const handleChangeData = (field) => (event) => {
    dispatch({
      type: ACTIONS.UPDATE_FIELD,
      field,
      value: event.target.value
    });
  };
  const handleSubmit = () => {
    console.log('Submitting form data:', formData);
    //TODO - Add submission logic here
  };
  useEffect(() => {
    if (data) {
      const beforeRiskScore =
        data['Risk Score'] || data['Detectability'] * data['Severity'] * data['Probability'];

      const afterRiskScore =
        data['Risk Score (FM)'] ||
        data['Detectability (FM)'] * data['Severity (FM)'] * data['Probability (FM)'];

      const beforeHumanRiskScore =
        data['Human Risk Score'] ||
        data['Human Detectability'] * data['Human Severity'] * data['Human Probability'];

      const afterHumanRiskScore =
        data['Human Risk Score (FM)'] ||
        data['Human Detectability (FM)'] *
          data['Human Severity (FM)'] *
          data['Human Probability (FM)'];

      const scores = {
        before: {
          ai: {
            Detectability: data['Detectability'],
            Severity: data['Severity'],
            Probability: data['Probability'],
            RiskScore: beforeRiskScore
          },
          humans: {
            Detectability: data['Human Detectability'] || 0,
            Severity: data['Human Severity'] || 0,
            Probability: data['Human Probability'] || 0,
            RiskScore: beforeHumanRiskScore || 0
          }
        },
        afterMitigation: {
          ai: {
            Detectability: data['Detectability (FM)'],
            Severity: data['Severity (FM)'],
            Probability: data['Probability (FM)'],
            RiskScore: afterRiskScore
          },
          humans: {
            Detectability: data['Human Detectability (FM)'] || 0,
            Severity: data['Human Severity (FM)'] || 0,
            Probability: data['Human Probability (FM)'] || 0,
            RiskScore: afterHumanRiskScore || 0
          }
        }
      };

      dispatch({
        type: ACTIONS.SET_INITIAL_DATA,
        data,
        scores
      });
    }
  }, [data]);

  return (
    <Paper elevation={2} sx={{ p: 1, pb: 2, minHeight: '78lvh' }}>
      <Grid sx={{ marginTop: '0.1rem' }} container spacing={1}>
        {/* Additional Information Section */}
        <RiskAssessmentInfo formData={formData} />

        {/* Scores Section */}
        <RiskAssessmentScore
          formData={formData}
          isPostMitigation={selectedRisk.isPostMitigation}
          votePreview={true}
        />

        {/* Risk Assessment Form Section */}
        <RiskAssessmentForm formData={formData} handleChangeData={handleChangeData} />

        {/* Notes Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Notes
          </Typography>
          <TextField
            multiline
            rows={2}
            fullWidth
            placeholder="Enter notes here..."
            variant="outlined"
            value={formData.notes || ''}
            onChange={handleChangeData('notes')}
          />
        </Grid>
      </Grid>
      {/* <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={() => console.log('Back clicked')}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            endIcon={<ChevronRight />}
            onClick={() => console.log('Next clicked')}
          >
            Next
          </Button>
        </Box>

        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
    </Paper>
  );
};

export default RiskReviewHostForm;
