import { Box } from '@mui/material';

//each score
const ScoreBox = ({ value, className, isActive }) => (
  <Box
    className={isActive ? className : undefined}
    sx={{
      fontSize: '1.1rem',
      minHeight: '1.5rem',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: !isActive ? 'white' : Number(value) > 3 ? 'white' : 'black',
      fontWeight: 'bold',

      backgroundColor: !isActive ? '#d1d1d1' : undefined // Gray when not active
    }}
  >
    {isNaN(Number(value)) ? 0 : Number(value)}
  </Box>
);
export default ScoreBox;
