const { useAuth } = require('contexts/auth-context');
const { useEffect } = require('react');

const ProtectedRoute = ({ isAllowed = true, children }) => {
  const { user, navigateToSignIn } = useAuth();

  useEffect(() => {
    if (!user?.email || !user?.token) {
      navigateToSignIn();
    }
  }, [user, navigateToSignIn]);

  return <>{children}</>;
};

export default ProtectedRoute;
