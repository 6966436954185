import { Box, Tab, Tabs, tabsClasses } from '@mui/material';
import { useOrgData } from 'contexts/org-data-context';
import {
  COMPLIANCE_DYNAMIC_TABS as DYNAMIC_TABS,
  RISK_PRODUCT_TYPES,
  COMPLIANCE_TABS as TABS
} from 'lib/constants/compliance';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import ComplianceHeader from './compliance-header';

const ComplianceTabs = () => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(false);
  const {
    state: { selectedCompliance }
  } = useOrgData();
  const [computedTabs, setComputedTabs] = useState([]);
  const [searchParams] = useSearchParams();
  const prId = searchParams.get('prId');
  const prType = searchParams.get('prType');

  useEffect(() => {
    const dyanmicArr = Array.isArray(selectedCompliance)
      ? DYNAMIC_TABS.filter((tab) => selectedCompliance.indexOf(tab.route) > -1)
      : [];
    let updatedTabs = TABS.map((tab) =>
      tab.name === 'PRODUCTS' && prId ? { ...tab, name: 'EDIT PRODUCT' } : tab
    );
    updatedTabs = updatedTabs.filter(
      (tab) => !(RISK_PRODUCT_TYPES.indexOf(prType) < 0 && tab.name === 'RISK')
    );

    const newTabs = updatedTabs.concat(dyanmicArr);
    setComputedTabs(newTabs);

    setTabIndex(
      newTabs.findIndex((tab) => location.pathname.indexOf('/' + tab.route) > -1) > -1
        ? newTabs.findIndex((tab) => location.pathname.indexOf('/' + tab.route) > -1)
        : false
    );
  }, [selectedCompliance, location.pathname, prId, prType]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', mb: 1 }}>
        <ComplianceHeader />
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Compliance Tabs"
          sx={{
            /* make arrows always visible */
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 }
            },
            mb: 1
          }}
        >
          {computedTabs.map((tab, index) => (
            <Tab
              key={tab.name}
              label={tab.name}
              component={Link}
              to={{ ...location, pathname: tab.route }}
            />
          ))}
        </Tabs>
      </Box>
    </React.Fragment>
  );
};

export default ComplianceTabs;
