import { httpService } from './http.service.js';

const ENDPOINT = 'ai-actors';

export const aiActorsService = {
  getRoles,
  getAllRoles
};

async function getRoles() {
  return await httpService.get(`${ENDPOINT}/roles`);
}
async function getAllRoles() {
  return await httpService.get(`${ENDPOINT}/all`);
}
