import { Container } from '@mui/material';
import AdminUserIndex from 'components/admin/admin-users/admin-user-index';
import AdminVerticalMenu from 'components/admin/Admin-vertical-menu';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const AdminIndex = () => {
  return (
    <React.Fragment>
      <Container
        maxWidth="xl"
        sx={{ display: 'flex', marginLeft: 0, paddingX: '0 !important', paddingBottom: 2 }}
      >
        <AdminVerticalMenu />
        <Suspense>
          <Outlet />
        </Suspense>
      </Container>
    </React.Fragment>
  );
};

export default AdminIndex;
