import styles from '../components/compliance/risk/compliance-risk-index.module.css';

export const getOrgNameById = (orgId, orgList) => {
  const org = orgList.find((org) => org._id === orgId);
  return org ? org.name : 'Unknown';
};

export const getRiskMqttTopic = (sessionId) => {
  return `linesight/risks/${sessionId}`;
};

export const getRiskScoreClass = (score) => {
  if (Number(score) >= 1 && Number(score) <= 23) {
    return styles['risk-level-low'];
  } else if (Number(score) >= 24 && Number(score) <= 35) {
    return styles['risk-level-med'];
  } else if (Number(score) >= 36 && Number(score) <= 125) {
    return styles['risk-level-high'];
  }
  return '';
};

export const getRiskNumericClass = (prob) => {
  if (Number(prob) >= 1 && Number(prob) <= 6) return styles[`risk-level-${Number(prob)}`];
  if (Number(prob) > 6) return styles['risk-level-high'];
  return '';
};