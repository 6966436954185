import { AccountCircle, Logout, Settings } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { startCase } from 'lodash';
import { useMemo } from 'react';

const UserMenu = ({ anchorEl, onClose, user, onLogout, onMenuNav }) => {
  const open = Boolean(anchorEl);

  const adminRootOpts = useMemo(() => {
    return (
      (user.isRoot || user.isAdmin) && (
        <Box>
          <MenuItem onClick={() => onMenuNav('users')}>
            <ListItemIcon>
              <PeopleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Users</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => onMenuNav('settings')}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
        </Box>
      )
    );
  }, [user, onMenuNav]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      slotProps={{
        paper: {
          elevation: 3,
          sx: {
            minWidth: '200px',
            mt: 1
          }
        }
      }}
    >
      {/* User Info Section */}
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 'fontWeightBold' }}>
          {user?.name}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {startCase(user?.accessRoles?.[0]) || 'User'}
        </Typography>
      </Box>

      <Divider />

      {/* Menu Items */}
      <MenuItem onClick={() => onMenuNav(`profile`)}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        <ListItemText>Profile</ListItemText>
      </MenuItem>

      {adminRootOpts}

      <Divider />
      <MenuItem onClick={onLogout} sx={{ color: 'error.main' }}>
        <ListItemIcon>
          <Logout fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
