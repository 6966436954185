import { Box } from '@mui/material';
import { useOutletContext, useParams } from 'react-router-dom';
import RiskReviewerForm from './risk-reviewer-form';
import { useMemo } from 'react';

const RiskReviewerIndex = () => {
  // eslint-disable-next-line no-unused-vars
  const [selectedRisk, displayName, setDisplayName, isConnected, doPublish, browserId] =
    useOutletContext();
  const { sessionId } = useParams();

  const hasVoted = useMemo(() => {
    if (!selectedRisk) return false;
    if (selectedRisk.isPostMitigation) {
      return selectedRisk.reviews_post?.findIndex((rev) => rev.id === browserId) > -1;
    }
    return selectedRisk.reviews?.findIndex((rev) => rev.id === browserId) > -1;
  }, [selectedRisk, browserId]);

  const handleSubmitRiskVote = (votes) => {
    console.log('handleSubmitRiskVote', votes);
    const review = {
      displayName,
      timestamp: new Date().getTime(),
      ...votes
    };
    doPublish({ action: 'UPDATE_REVIEW', data: review });
  };

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <RiskReviewerForm
        sessionId={sessionId}
        hasVoted={hasVoted}
        isPostMitigation={selectedRisk.isPostMitigation}
        riskName={selectedRisk?.risk?.['HA Number']}
        riskDescription={selectedRisk?.risk?.['Hazardous Event or Situation']}
        riskMitigation={selectedRisk?.risk?.['Mitigation Measures']}
        onSubmitRiskVote={handleSubmitRiskVote}
      />
    </Box>
  );
};

export default RiskReviewerIndex;
