import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useReducer } from 'react';
import { riskService } from 'services/risk.service';
import RiskAssessmentScore from './risk-assessment-score/risk-assessmemt-score';
import RiskAssessmentForm from './risk-assessment-form';
import RiskAssessmentInfo from './risk-assessment-info';
import RiskReviewNodeGraphIndex from 'components/risk-review/host/node-graph/risk-review-node-graph-index';

const initialState = {
  riskNumber: '',
  source: 'AI/employee/role',
  productVersion: '',
  mitigationByAI: '',
  mitigationByHuman: '',
  mitigationStatus: 'A',
  validationNum: '',
  validationStatus: '',
  verificationNum: '',
  verificationStatus: '',
  validationTest: '',
  verificationTest: '',
  notes: '',
  scores: {
    before: {
      ai: { Probability: 0, Severity: 0, Detectability: 0, RiskScore: 0 },
      humans: { Probability: 0, Severity: 0, Detectability: 0, RiskScore: 0 }
    },
    afterMitigation: {
      ai: { Probability: 0, Severity: 0, Detectability: 0, RiskScore: 0 },
      humans: { Probability: 0, Severity: 0, Detectability: 0, RiskScore: 0 }
    }
  }
};

const ACTIONS = {
  UPDATE_FIELD: 'UPDATE_FIELD',
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  UPDATE_SCORES_BEFORE: 'UPDATE_SCORES_BEFORE',
  UPDATE_SCORES_AFTER: 'UPDATE_SCORES_AFTER'
};

const formReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_FIELD:
      return {
        ...state,
        [action.field]: action.value
      };

    case ACTIONS.SET_INITIAL_DATA:
      return {
        ...state,
        ...action.data,
        scores: action.scores
      };

    case ACTIONS.UPDATE_SCORES_BEFORE:
      return {
        ...state,
        scores: {
          ...state.scores,
          before: {
            ...state.scores.before,
            [action.rowLabel]: {
              ...state.scores.before[action.rowLabel],
              ...action.scores
            }
          }
        }
      };
    case ACTIONS.UPDATE_SCORES_AFTER:
      return {
        ...state,
        scores: {
          ...state.scores,
          afterMitigation: {
            ...state.scores.afterMitigation,
            [action.rowLabel]: {
              ...state.scores.afterMitigation[action.rowLabel],
              ...action.scores
            }
          }
        }
      };

    default:
      return state;
  }
};
const RiskAssessmentDialog = ({ open, onClose, data, prId }) => {
  console.log('DATA!!', data);
  console.log('prID!!', prId);

  const [formData, dispatch] = useReducer(formReducer, initialState);
  const queryClient = useQueryClient();

  const { mutate: updateRisk, isPending } = useMutation({
    mutationFn: ({ id, formattedData }) =>
      riskService.updateAppRequirementsOverrides(id, formattedData),
    onSuccess: () => {
      queryClient.invalidateQueries(['riskData']);
      onClose();
    },
    onError: (error) => {
      console.error('Failed to update risk assessment', error);
    }
  });

  const handleChangeData = (field) => (event) => {
    dispatch({
      type: ACTIONS.UPDATE_FIELD,
      field,
      value: event.target.value
    });
  };
  const handleSubmit = async () => {
    // Validate required fields before submission
    if (!data._id) {
      throw new Error('Missing risk ID');
    }
    try {
      // Format risk data
      const formattedData = riskObjToOverrides(formData);

      console.log('Submitting risk assessment:', {
        data: data,
        original: formData,
        formatted: formattedData
      });

      // // Submit update
      updateRisk({ id: data._id, formattedData });
    } catch (error) {
      console.error('Failed to update risk assessment:', error);
    }
  };

  const riskObjToOverrides = (riskObj) => ({
    // Basic Risk Info
    'HA Number': riskObj.riskNumber,
    Source: riskObj.source,
    'Product Version': riskObj.productVersion,

    // Mitigation Details
    'Mitigation Status': riskObj.mitigationStatus,
    'Mitigation Measures': riskObj['Mitigation Measures'],
    'Mitigation by AI': riskObj.mitigationByAI,
    'Mitigation by Human': riskObj.mitigationByHuman,

    // Test Details
    'Validation Test #': riskObj.validationNum,
    'Validation Status': riskObj.validationStatus,
    'Verification Test #': riskObj.verificationNum,
    'Verification Status': riskObj.verificationStatus,

    // Risk Scores Before Mitigation - AI
    Detectability: riskObj.scores.before.ai.Detectability,
    Severity: riskObj.scores.before.ai.Severity,
    Probability: riskObj.scores.before.ai.Probability,
    'Risk Score': riskObj.scores.before.ai.RiskScore,

    // Risk Scores After Mitigation - AI
    'Detectability (FM)': riskObj.scores.afterMitigation.ai.Detectability,
    'Severity (FM)': riskObj.scores.afterMitigation.ai.Severity,
    'Probability (FM)': riskObj.scores.afterMitigation.ai.Probability,
    'Risk Score (FM)': riskObj.scores.afterMitigation.ai.RiskScore,

    // Risk Scores Before Mitigation - Humans
    'Human Detectability': riskObj.scores.before.humans.Detectability,
    'Human Severity': riskObj.scores.before.humans.Severity,
    'Human Probability': riskObj.scores.before.humans.Probability,
    'Human Risk Score': riskObj.scores.before.humans.RiskScore,

    // Risk Scores After Mitigation - Humans
    'Human Detectability (FM)': riskObj.scores.afterMitigation.humans.Detectability,
    'Human Severity (FM)': riskObj.scores.afterMitigation.humans.Severity,
    'Human Probability (FM)': riskObj.scores.afterMitigation.humans.Probability,
    'Human Risk Score (FM)': riskObj.scores.afterMitigation.humans.RiskScore,

    // Additional Fields
    Notes: riskObj.notes
  });
  useEffect(() => {
    if (data) {
      const beforeRiskScore =
        data['Risk Score'] || data['Detectability'] * data['Severity'] * data['Probability'];

      const afterRiskScore =
        data['Risk Score (FM)'] ||
        data['Detectability (FM)'] * data['Severity (FM)'] * data['Probability (FM)'];

      const beforeHumanRiskScore =
        data['Human Risk Score'] ||
        data['Human Detectability'] * data['Human Severity'] * data['Human Probability'];

      const afterHumanRiskScore =
        data['Human Risk Score (FM)'] ||
        data['Human Detectability (FM)'] *
          data['Human Severity (FM)'] *
          data['Human Probability (FM)'];

      const scores = {
        before: {
          ai: {
            Detectability: Number(data['Detectability']), // Add Number()
            Severity: Number(data['Severity']),
            Probability: Number(data['Probability']),
            RiskScore: Number(beforeRiskScore)
          },
          humans: {
            Detectability: Number(data['Human Detectability']) || 0,
            Severity: Number(data['Human Severity']) || 0,
            Probability: Number(data['Human Probability']) || 0,
            RiskScore: Number(beforeHumanRiskScore) || 0
          }
        },
        afterMitigation: {
          ai: {
            Detectability: Number(data['Detectability (FM)']),
            Severity: Number(data['Severity (FM)']),
            Probability: Number(data['Probability (FM)']),
            RiskScore: Number(afterRiskScore)
          },
          humans: {
            Detectability: Number(data['Human Detectability (FM)']) || 0,
            Severity: Number(data['Human Severity (FM)']) || 0,
            Probability: Number(data['Human Probability (FM)']) || 0,
            RiskScore: Number(afterHumanRiskScore) || 0
          }
        }
      };
      // Map incoming data to form fields
      const mappedData = {
        riskNumber: data['HA Number'] || '',
        source: data['Source'] || 'AI/employee/role',
        productVersion: data['Product Version'] || '',
        mitigationByAI: data['Mitigation by AI'] || '',
        mitigationByHuman: data['Mitigation by Human'] || '',
        mitigationStatus: data['Mitigation Status'] || 'A',
        validationNum: data['Validation Test #'] || '',
        validationStatus: data['Validation Status'] || '',
        verificationNum: data['Verification Test #'] || '',
        verificationStatus: data['Verification Status'] || '',
        notes: data['Notes'] || '',
        'Mitigation Measures': data['Mitigation Measures'] || ''
      };

      dispatch({
        type: ACTIONS.SET_INITIAL_DATA,
        data: mappedData,
        scores
      });
    }
  }, [data]);
  const handleScoreChange = (timeframe, key, value, rowLabel = 'humans') => {
    // Calculate risk score if a non-RiskScore field is changed
    if (key !== 'RiskScore') {
      const scores =
        timeframe === 'before'
          ? formData.scores.before[rowLabel]
          : formData.scores.afterMitigation[rowLabel];

      const updatedScores = { ...scores, [key]: value };
      const riskScore =
        updatedScores.Probability * updatedScores.Severity * updatedScores.Detectability;

      // Update both the value and the calculated risk score
      dispatch({
        type: timeframe === 'before' ? ACTIONS.UPDATE_SCORES_BEFORE : ACTIONS.UPDATE_SCORES_AFTER,
        rowLabel,
        scores: { [key]: value }
      });

      // Update risk score
      dispatch({
        type: timeframe === 'before' ? ACTIONS.UPDATE_SCORES_BEFORE : ACTIONS.UPDATE_SCORES_AFTER,
        rowLabel,
        scores: { RiskScore: riskScore }
      });
    } else {
      dispatch({
        type: timeframe === 'before' ? ACTIONS.UPDATE_SCORES_BEFORE : ACTIONS.UPDATE_SCORES_AFTER,
        rowLabel,
        scores: { [key]: value }
      });
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'primary.main',
          fontWeight: 'bold'
        }}
      >
        {' '}
        Edit Risk #{data?.['HA Number'] || 'N/A'} Assessment
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <RiskAssessmentInfo formData={data} />
        <Divider />
        <Grid sx={{ marginTop: '0.1rem' }} container spacing={1}>
          <RiskAssessmentForm formData={formData} handleChangeData={handleChangeData} />

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <RiskAssessmentScore
                formData={formData}
                isPostMitigation={true}
                votePreview={false}
                onScoreChange={handleScoreChange}
              />

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  placeholder="Enter notes here..."
                  variant="outlined"
                  value={formData.notes || ''}
                  onChange={handleChangeData('notes')}
                />
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Box sx={{ height: '100%' }}>
                <Paper
                  elevation={2}
                  sx={{
                    height: 'calc(100% - 24px)',
                    p: 1,
                    mt: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <RiskReviewNodeGraphIndex productId={prId} targetNode={data?.['HA Number']} />
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button disabled={isPending} variant="contained" color="primary" onClick={handleSubmit}>
          {isPending ? 'Updating' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RiskAssessmentDialog;
