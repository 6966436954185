import { Box, IconButton, Modal, Stack } from '@mui/material';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useConfirmDialog } from 'contexts/confirm-dialog-context';
import { DeleteIcon, EditIcon } from 'icons';
import { useState } from 'react';
import { adminService } from 'services/admin.service';
import { usersService } from 'services/users.service';
import CreateEditUser from './create-edit-user';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <Stack direction="row" spacing={1}></Stack>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector slotProps={{ tooltip: { title: 'Change density' } }} />
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter sx={{ pl: 2 }} />
    </GridToolbarContainer>
  );
};
const defaultUser = () => {
  return {
    firstName: '',
    lastName: '',
    orgId: null,
    aiActors: [],
    email: ''
  };
};
const AdminUserIndex = () => {
  const queryClient = useQueryClient();
  const { openConfirmDialog } = useConfirmDialog();
  const [openModal, setOpenModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(defaultUser());
  const { data: users = [], isPending } = useQuery({
    queryKey: ['admin-users'],
    queryFn: async () => {
      const result = await adminService.getAllUsers();
      console.log('useQuery result!!!:', result);
      return result;
    },
    enabled: true
  });
  const { mutate: deleteMutate, isPending: isPendingDelete } = useMutation({
    mutationFn: (userId) => usersService.remove(userId),
    onSuccess: () => queryClient.invalidateQueries(['users'])
  });
  const { mutate: saveMutate, isPending: isPendingSave } = useMutation({
    mutationFn: (user) => usersService.save(user),
    onSuccess: () => queryClient.invalidateQueries(['admin-user']) // Change this from 'users' to 'admin-user'
  });
  const columns = [
    {
      field: 'picture',
      headerName: 'Avatar',
      minWidth: 60,
      flex: 0,
      renderCell: (params) => (
        <Box
          sx={{
            width: 32,
            height: 32,
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#f5f5f5'
          }}
        >
          {params.value ? (
            <Box
              component="img"
              src={params.value}
              alt="Avatar"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                transform: 'scale(1.25)',
                color: 'text.secondary'
              }}
            />
          )}
        </Box>
      )
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 130,
      flex: 1
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 130,
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 120,
      flex: 1
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 120,
      flex: 1
    },
    {
      field: 'orgId',
      headerName: 'Organization',
      minWidth: 180,
      flex: 1
    },
    {
      field: 'jobTitle',
      headerName: 'Title',
      minWidth: 180,
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 120,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => handleEdit(params.row)}
            sx={{ color: 'primary.main' }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              openConfirmDialog({
                title: 'Confirm Delete',
                body: `Delete user ${params.row.firstName} ${params.row.lastName}? This action is permanent.`,
                onConfirm: async () => {
                  console.log('Deleting user:', params.row);
                  deleteMutate(params.row._id);
                },
                onCancel: () => console.log('Delete canceled')
              });
            }}
            sx={{ color: 'error.main' }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Stack>
      )
    }
  ];
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleRolesChange = (event) => {
    const { value } = event.target;
    setUserToEdit((prevUser) => ({ ...prevUser, aiActors: value }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserToEdit((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleEdit = (user) => {
    setUserToEdit(user);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setUserToEdit({
      ...defaultUser(),
      _id: undefined,
      orgId: '' //TODO chose org
    });
    setOpenModal(true);
  };

  const handleSubmit = async (event) => {
    console.log('user TO SAVE', userToEdit);
    event.preventDefault();
    try {
      await saveMutate(userToEdit);
    } catch (error) {
      console.error('Error saving user:', error);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100svh - 10.5rem)',
        paddingBottom: '1rem'
      }}
    >
      <Box sx={{ margin: '2rem' }}>
        <DataGrid
          getRowId={(row) => row._id}
          rows={users}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[5, 10, 25, 50, 100, { label: 'All', value: -1 }]}
          pagination
          disableRowSelectionOnClick
          disableColumnResize
          getRowHeight={() => 'auto'}
          loading={isPending}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            '& .MuiDataGrid-cell': {
              minHeight: 40,
              maxHeight: 120,
              display: 'flex',
              alignItems: 'center'
            }
          }}
        />
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            maxWidth: '1400px',
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            p: 4
          }}
        >
          <CreateEditUser
            userToEdit={userToEdit}
            handleRolesChange={handleRolesChange}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            disabled={isPendingSave}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AdminUserIndex;
