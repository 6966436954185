import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Fade } from '@mui/material';
import AuthProvider from 'contexts/auth-context';
import { SnackbarProvider } from 'notistack';
import ErrorFallBackComponent from 'pages/errors/error-fallback-component';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfirmDialogProvider } from 'contexts/confirm-dialog-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary fallbackRender={ErrorFallBackComponent}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        preventDuplicate={true}
        disableWindowBlurListener={true}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <ConfirmDialogProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </ConfirmDialogProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
