import { Navigate, Outlet, useParams } from 'react-router-dom';

const AccessRoleRoutes = ({ user, redirectTo = {} }) => {
  const { orgId } = useParams();
  
  return user.isRoot || (user?.orgIds ?? []).indexOf(orgId) > -1 ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} replace />
  );
};

export default AccessRoleRoutes;
