import { useNavigate } from 'react-router-dom';

const { useAuth } = require('contexts/auth-context');
const { useEffect } = require('react');

const RootUserRoute = ({ children }) => {
  const { user, navigateToSignIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.email || !user?.token) {
      return navigateToSignIn();
    }
    if (!user?.isRoot) {
      return navigate(`/compliance/${user.orgId}/dashboard`, {replace: true});
    }
  }, [user, navigateToSignIn, navigate]);

  return <>{user?.isRoot && children}</>;
};

export default RootUserRoute;
