import { Grid, Typography } from '@mui/material';

const RiskAssessmentInfo = ({ formData }) => {
  const infoFields = [
    { label: 'Hazardous Event', value: formData?.['Hazardous Event or Situation'] },
    { label: 'Hazard Categorization', value: formData?.['Hazard categorization'] },
    { label: 'Possible Harm Caused', value: formData?.['Possible Harm Caused'] }
    // { label: 'Mitigation Measures', value: formData['Mitigation Measures'] }
  ];

  const fieldsWithData = infoFields.filter((field) => field.value);

  return (
    <Grid item xs={12} sx={{ mb: 1 }}>
      <Grid container spacing={1}>
        {fieldsWithData.map(({ label, value }) => (
          <Grid item xs={4} key={label}>
            <Typography variant="subtitle1" fontWeight="bold">
              {label}:
            </Typography>
            <Typography>{value}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default RiskAssessmentInfo;
