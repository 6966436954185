import Grid from '@mui/material/Unstable_Grid2';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import RiskReviewNodeGraphIndex from './node-graph/risk-review-node-graph-index';
import RiskReviewHostForm from './risk-review-host-form';
import RiskReviewUsersTable from './risk-review-users-table';
import { Paper } from '@mui/material';

const RiskReviewHostIndex = () => {
  // eslint-disable-next-line no-unused-vars
  const [selectedRisk] = useOutletContext();

  const tableData = useMemo(() => {
    if (!selectedRisk) return [];
    if (selectedRisk.isPostMitigation) {
      return selectedRisk.reviews_post;
    }
    return selectedRisk.reviews;
  }, [selectedRisk]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} lg={7}>
        <RiskReviewHostForm selectedRisk={selectedRisk} />
      </Grid>
      <Grid xs={12} lg={5} spacing={2}>
        <Paper elevation={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <RiskReviewUsersTable data={tableData} />
        </Paper>
        <Paper
          elevation={2}
          sx={{
            height: '315px',
            p: 1,
            mt: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <RiskReviewNodeGraphIndex
            productId={selectedRisk.prId}
            targetNode={selectedRisk?.risk?.['HA Number']}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RiskReviewHostIndex;
