import { Box, InputBase } from '@mui/material';
import { useState } from 'react';

const EditableScore = ({ value, onChange, onFinish, min = 1, max = 6, error }) => {
  const [localValue, setLocalValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);

    if (!/^\d*$/.test(newValue)) {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);

    if (newValue === '') {
      onChange({ target: { value: '' } });
    } else {
      const parsedValue = parseInt(newValue);
      if (!isNaN(parsedValue)) {
        onChange({ target: { value: parsedValue } });
      }
    }
  };

  const handleBlur = () => {
    if (localValue === '' || isInvalid) {
      setLocalValue(min);
      onChange({ target: { value: min } });
      setIsInvalid(false);
    }
    onFinish();
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <InputBase
        autoFocus
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (localValue === '' || isInvalid) {
              setLocalValue(min);
              onChange({ target: { value: min } });
              setIsInvalid(false);
            }
            onFinish();
          }
        }}
        type="text"
        inputMode="numeric"
        pattern="[0-9]+"
        error={error || isInvalid}
        sx={{
          fontSize: '1.1rem',
          width: '100%',
          height: '100%',
          '& input': {
            border: error || isInvalid ? '1px solid red' : 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none'
          }
        }}
        inputProps={{
          min,
          max,
          style: {
            textAlign: 'center',
            height: '100%',
            WebkitAppearance: 'none'
          }
        }}
      />
      {(error || isInvalid) && (
        <Box
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            color: 'red',
            fontSize: '0.7rem',
            textAlign: 'center',
            backgroundColor: 'white',
            zIndex: 100,
            marginBottom: '0.5rem',
            py: 0.25
          }}
        >
          {isInvalid ? 'Must be a number' : `Must be between ${min}-${max}`}
        </Box>
      )}
    </Box>
  );
};

export default EditableScore;
